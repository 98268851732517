import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function FullStackSoftwareEngineer () {
  return (
    <CareerLayout title='Market manager' location='Winnipeg, MB'>
      <h2>Overview</h2>
      <p>Trexity is looking for proven leaders who want to change local delivery to take the reins on our markets to grow and scale them. Market Managers own the P&amp;L for their markets, which means responsibility for driver and merchant acquisition, service quality, market growth, and strategic direction.</p>
      <p>You will work closely with every aspect of the company for product, engagement, online acquisition, brand, and more to drive growth and meet business objectives. As a Market Manager you will report to the General Manager in your region. You will own the Winnipeg/Manitoba region and will work with the other General Managers to shape the vision for the region.</p>
      <p className='mb'>Trexity’s mission is to allow small and medium-sized merchants to compete against the headless and soulless retail behemoths by better servicing their customers. Trexity makes it easy to close the logistics gap and allows merchants to once again own their customer’s doorstep. If that’s a vision you want to turn into reality, then we want to meet you.</p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Manage the market P&amp;L to achieve growth while keeping within budget</li>
        <li>Achieve growth goals (rides, activations, revenue)</li>
        <li>Strengthen and grow the driver community via events, promotions, communications, and training</li>
        <li>Build the merchant base through local marketing efforts, including building partnerships, managing brand ambassadors, and helping localize large-scale promotion</li>
        <li>Support the public relations and government relations teams by representing Trexity</li>
        <li>Work with government relations experts to navigate the regulatory landscape</li>
        <li>Synthesize market feedback and recommendations to improve our offering</li>
        <li>Build and maintain reports, dashboards, and metrics to monitor performance</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>7+ years of operations or strategy experience</li>
        <li>Experience scaling an organization and managing people</li>
        <li>Entrepreneurial attitude with a get-it-done attitude</li>
        <li>Naturally connect with drivers as easily as you connect with business partners and local government officials</li>
        <li>Quantitative by nature and obsessed with process improvement</li>
        <li>Experience working cross-functionally</li>
      </ul>

      <h3>Bonus points</h3>
      <ul className='trx-bullet mb'>
        <li>Deep region knowledge and network</li>
        <li>Two-sided market experience</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
      </ul>
    </CareerLayout>
  )
}
